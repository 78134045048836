<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<notificacioncontacto-modals
			:modelName="modelName"
		></notificacioncontacto-modals>
	</div>
</template>

<script>
import notificacioncontactoActions from './NotificacioncontactoActions';
import notificacioncontactoModals from './NotificacioncontactoModals.vue';

export default {
	name: 'notificacioncontacto-grid',
	components: {
		'notificacioncontacto-modals': notificacioncontactoModals
	},
	data() {
		return {
			modelName: 'notificacioncontacto',
			actions: notificacioncontactoActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
